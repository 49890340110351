<template>
  <div class="tutoring-mobile-container text-center" :style="returnStyling">
    <div class="text-h3 text-kinderland-pink w700 q-mb-md">
      Tutoría
    </div>
    <div class="text-h6 q-mb-lg">
      Kinderland le abre las puertas en las tardes a los niños de primaria
      cuando salen de su escuela; aquí se les da almuerzo, descanso y se les
      verifica todas las tareas de cada asignatura como:
    </div>
    <div class="text-h6 text-kinderland-pink w700 q-mb-lg">
      Español, Matemáticas, Religión, Ciencias Sociales, Ingles
    </div>
    <div class="text-h6 q-mb-lg">
      Con tutorías individuales, calificadas y con experiencia.
      <br />Atendemos niños de 6 años a 12 años. Le afianzamos sus conocimientos
      y le resolvemos todas sus dudas. <br /><br />El estudiante llega a casa
      con las tareas resueltas para tener descanso y esparcimiento. Supervisamos
      las notas de los estudiantes con su boletín.
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    returnStyling() {
      if (this.$q.screen.name == "xs") return "padding-top: 100px;";
      else return "padding-left: 20%; padding-right: 20%; padding-top: 120px;";
    },
  },
};
</script>
