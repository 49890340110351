<template>
  <q-page class="bg-kinderland-blue">
    <Navbar />
    <Hero id="home" />
    <div class="row justify-center q-pb-xl bg-white">
      <img
        src="@/assets/assets/arrow-bottom-blue.png"
        class="cursor-pointer"
        width="90"
        height="56"
        alt="Flecha - Quienes somos"
      />
    </div>
    <About id="about" />
    <div class="row justify-center" style="background-color: #90D1F3">
      <img
        src="@/assets/assets/arrow-bottom-white.png"
        class="cursor-pointer"
        width="90"
        height="56"
        alt="Flecha - Mision"
      />
    </div>
    <Info />
    <Services id="services" />
    <Tutoring class="sm-hide xs-hide" />
    <TutoringMobile class="xl-hide lg-hide md-hide" />
    <Footer id="contact" />
  </q-page>
</template>

<script>
import Navbar from "@/components/Navbar";
import Hero from "@/components/Hero";
import About from "@/components/About";
import Info from "@/components/Info";
import Services from "@/components/Services";
import Tutoring from "@/components/Tutoring";
import TutoringMobile from "@/components/TutoringMobile";
import Footer from "@/components/Footer";

export default {
  components: {
    Navbar,
    Hero,
    About,
    Info,
    Services,
    Tutoring,
    TutoringMobile,
    Footer,
  },
};
</script>
