<template>
  <div>
    <div class="fixed-top q-mt-lg  sm-hide xs-hide">
      <div class="row justify-center">
        <div class="col-lg-7 col-md-12">
          <div class="row justify-center">
            <img src="@/assets/assets/rainbow.png" style="z-index: 200" />
            <div class="col-lg-4" />
            <img
              src="@/assets/assets/sun.png"
              style="z-index: 200; width: 94px; height: 68px; margin-top: 22px;"
            />
            <!-- <div class="col" >
            
          </div> -->
          </div>
          <div class="row" style="margin-top: -18px;">
            <div class="col" style="margin-top: -30px; margin-right: -80px;">
              <img src="@/assets/assets/left_ribbon.png" />
            </div>
            <div class="col-10" style="z-index: 100">
              <div class="bg-kinderland-pink q-py-lg text-center">
                <a
                  class="text-h4 w700 text-white q-px-lg"
                  style="text-decoration: none;"
                  href="#home"
                  v-smooth-scroll
                  >Inicio</a
                >
                <a
                  class="text-h4 w700 text-white q-px-lg"
                  style="text-decoration: none;"
                  href="#about"
                  v-smooth-scroll
                  >Quienes Somos</a
                >
                <a
                  class="text-h4 w700 text-white q-px-lg"
                  style="text-decoration: none;"
                  href="#services"
                  v-smooth-scroll
                  >Servicios</a
                >
                <a
                  class="text-h4 w700 text-white q-px-lg"
                  style="text-decoration: none;"
                  href="#contact"
                  v-smooth-scroll
                  >Contáctenos</a
                >
              </div>
            </div>
            <div class="col" style="margin-top: -30px; margin-left: -30px;">
              <img src="@/assets/assets/right_ribbon.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="fixed-top xl-hide lg-hide md-hide bg-kinderland-pink row q-py-md items-center"
    >
      <div class="text-h5 text-white w700 q-px-md">Kinderland</div>
      <q-space />
      <q-btn
        icon="menu"
        flat
        round
        color="white"
        @click="menuSidepanel = true"
      />
    </div>
    <q-dialog
      v-model="menuSidepanel"
      maximized
      transition-show="slide-left"
      transition-hide="slide-right"
    >
      <q-card class="bg-kinderland-pink text-white">
        <q-card-actions class="q-pa-md" align="right">
          <q-btn icon="close" flat round @click="menuSidepanel = false" />
        </q-card-actions>
        <q-card-section class="absolute-center text-center full-width kl-font">
          <div class="q-mb-md">
            <a
              class="text-h4 w700 text-white q-px-lg"
              style="text-decoration: none;"
              href="#home"
              @click="menuSidepanel = false"
              >Inicio</a
            >
          </div>
          <div class="q-mb-md">
            <a
              class="text-h4 w700 text-white q-px-lg"
              style="text-decoration: none;"
              href="#about"
              @click="menuSidepanel = false"
              >Quienes Somos</a
            >
          </div>
          <div class="q-mb-md">
            <a
              class="text-h4 w700 text-white q-px-lg"
              style="text-decoration: none;"
              href="#services"
              @click="menuSidepanel = false"
              >Servicios</a
            >
          </div>
          <div class="q-mb-md">
            <a
              class="text-h4 w700 text-white q-px-lg"
              style="text-decoration: none;"
              href="#contact"
              @click="menuSidepanel = false"
              >Contáctenos</a
            >
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuSidepanel: false,
    };
  },
};
</script>
